header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  line-height: 1.5;
  .top-header {
    background-color: #fff;
    width: 100%;
    @include transition(300ms);
    ul {
      margin: 0;
      text-align: center;
      li {
        padding: 0;
        @include inline-block;
        &::before {
          display: none;
        }
        a {
          color: $base-color;
          font-size: 14px;
          @include transition;
          position: relative;
          padding: 18px 0 20px 0;
          display: block;
          &:hover {
            color: $blue;
            text-decoration: none;
          }
          &.active {
            font-weight: bold;
            color: $blue;
            &::before {
              @include pseudo;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: $blue;
            }
          }
        }
        &+li {
          margin-left: 58px;
        }
      }
    }
  }
  .main-header {
    height: 104px;
    width: 100%;
    @include transition(300ms);
  }
  .logo {
    width: 220px;
    height: 55px;
    @include inline-block;
    @include image('../images/logo.png');
    background-size: contain;
    margin-top: 45px;
  }
  nav {
    @include inline-block;
  }
  .menu {
    margin: 49px 0 0 95px;
    li {
      padding: 0;
      @include inline-block;
      &::before {
        display: none;
      }
      a {
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: $base-color;
        @include transition;
        position: relative;
        padding-bottom: 17px;
        &:hover {
          color: $blue;
          text-decoration: none;
        }
        &.active {
          color: $blue;
          &::before {
            @include pseudo;
            width: 100%;
            height: 2px;
            background-color: $blue;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            max-width: 110px;
          }
        }
      }
      &+li {
        padding-left: 50px;
        &::before {
          background-color: $grey;
          @include size(5px);
          left: 22px;
          top: 10px;
          @include pseudo;
          border-radius: 100%;
        }
      }
    }
  }
  .quick-contact {
    @include inline-block;
    padding-left: 45px;
    position: relative;
    text-align: center;
    float: right;
    margin-top: 36px;
    .icon {
      left: 0;
      top: 9px;
      fill: $blue;
      @include size(30px);
    }
    span {
      display: block;
      font-size: 15px;
      margin-bottom: 3px;
    }
    a {
      display: block;
      font-size: 18px;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      color: $light-blue;
      @include transition;
      &:hover {
        text-decoration: none;
        color: $dark-blue;
      }
    }
  }
  .hamburger {
    display: none;
  }
  &.fixed-down {
    .top-header {
      position: fixed;
      top: -70px;
    }
    .main-header {
      position: fixed;
      top: -20px;
      background-color: #fff;
      box-shadow: 0 3px 3px rgba(0,0,0,0.1);
    }
  }
  &.fixed-up {
    .top-header {
      position: fixed;
      top: -70px;
    }
    .main-header {
      position: fixed;
      top: -20px;
      background-color: #fff;
      box-shadow: 0 3px 3px rgba(0,0,0,0.1);
    }
  }
}

@media (max-width: 1599px) {
  header {
    .menu {
      margin-left: 50px;
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  header {
    .menu, .top-header {
      display: none;
      height: 0;
    }
    .logo {
      width: 160px;
      height: 40px;
      margin-top: 25px;
    }
    .main-header {
      position: fixed;
      height: 76px;
    }
    .quick-contact {
      margin-top: 14px;
    }
    .hamburger {
      display: block;
      width: 28px;
      float: right;
      margin: 29px 0 0 40px;
      @include transition;
      &:active {
        transform: scale(0.8);
      }
      span {
        width: 100%;
        height: 3px;
        background-color: $blue;
        display: block;
        &+span {
          margin-top: 5px;
        }
      }
    }
    &.fixed-down, &.fixed-up {
      .main-header {
        top: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  header {
    .quick-contact {
      display: none;
    }
    .main-header {
      height: 60px;
    }
    .logo {
      margin-top: 18px;
    }
    .hamburger {
      margin-top: 20px;
    }
  }
}