.image-boxes {
  text-align: center;
  padding-bottom: 180px;
  background-color: $blue;
  .image-boxes-headline {
    font-size: 30px;
  }
  .image-boxes-text {
    width: 50%;
    margin: auto;
  }
  .items {
    margin-top: 0;
    margin-bottom: -60px;
    position: relative;
    z-index: 1;
    &.bottom-offset-150 {
      margin-bottom: -150px;
    }
    .item {
      position: relative;
      display: block;
      color: $base-color;
      box-shadow: 0 8px 20px rgba(0,0,0,0.1);
      border-radius: 4px;
      @include transition;
      &::before {
        @include pseudo;
        @include size(80px);
        border-radius: 100%;
        border: 2px solid $blue;
        top: -40px;
        left: 50%;
        margin-left: -40px;
        z-index: 1;
      }
      .icon {
        fill: $blue;
        left: 50%;
        &.icon-heart {
          @include size(45px);
          top: -19px;
          margin-left: -24px;
        }
        &.icon-mouse {
          @include size(41px);
          top: -21px;
          margin-left: -22px;
        }
        &.icon-shield {
          @include size(39px);
          top: -19px;
          margin-left: -19px;
        }
        &.icon-hands {
          @include size(36px);
          top: -16px;
          margin-left: -18px;
        }
        &.icon-syringe {
          @include size(42px);
          top: -20px;
          margin-left: -21px;
        }
      }
      .number {
        font-size: 30px;
        color: $blue;
        position: absolute;
        left: 50%;
        font-family: 'Rubik', sans-serif;
        top: -30px;
        margin-left: -9px;
      }
      .image-wrapper {
        height: 270px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include border-radius-extended(4px,4px,0,0);
        &::before {
          @include pseudo;
          @include size(100px);
          border-radius: 100%;
          left: 50%;
          top: -50px;
          background: #ffffff; /* Old browsers */
          background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 80%, #e6e6e6 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 80%,#e6e6e6 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #ffffff 0%,#ffffff 80%,#e6e6e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e6e6e6',GradientType=0 );
          margin-left: -50px;
        }
      }
      .item-text-container {
        padding: 40px;
        background-color: #fff;
        @include border-radius-extended(0,0,4px,4px);
        .item-headline {
          font-size: 24px;
          margin-bottom: 20px;
        }
        .item-text {
          font-size: 14px;
        }
        &.btn-fixed-bottom {
          position: relative;
          height: calc(100% - 270px);
          .btn {
            position: absolute;
            bottom: 45px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &:hover {
        text-decoration: none;
        color: $base-color;
        box-shadow: 0 8px 20px rgba(0,0,0,0.2);
      }
      &.bigger {
        margin-top: -30px;
        .image-wrapper {
          height: 320px;
        }
      }
      &.long {
        text-align: left;
        height: 100%;

        &::before {
          display: none;
        }
        .item-text-container {
          height: 100%;
          padding: 35px 30px 30px 30px;
          line-height: 1.8;
          .item-headline {
            margin-bottom: 10px;
          }
          .item-link {
            margin-bottom: 35px;
            font-weight: bold;
            @include inline-block;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
          ul {
            padding-top: 5px;
            li {
              padding: 0 0 3px 28px;
              &::before {
                left: 9px;
                top: 12px;
              }
            }
          }
          h4 {
            font-family: 'Open Sans',  sans-serif;
            color: $base-color;
            font-weight: bold;
            margin-bottom: 12px;
            font-size: 16px;
          }
        }
      }
    }
  }
  &.numbers {
    padding-top: 20px;
  }
}

@media (max-width: 1599px) {
  .image-boxes {
    .items {
      .item {
        .image-wrapper {
          height: 240px;
        }
        &.bigger {
          .image-wrapper {
            height: 290px;
          }
        }
        .item-text-container {
          .item-headline {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .image-boxes {
    .items {
      .item {
        .image-wrapper {
          height: 200px;
        }
        &.bigger {
          .image-wrapper {
            height: 250px;
          }
        }
      }
    }
    .image-boxes-headline {
      font-size: 26px;
    }
    .image-boxes-text {
      width: 70%;
    }
  }
}

@media (max-width: 991px) {
  .image-boxes {
    .items {
      margin-top: 85px;
      .item {
        &::before {
          @include size(64px);
          top: -32px;
          margin-left: -32px;
        }
        .image-wrapper {
          height: 150px;
          &::before {
            @include size(80px);
            margin-left: -40px;
            top: -40px;
          }
        }
        &.bigger {
          margin-top: -20px;
          .image-wrapper {
            height: 190px;
          }
        }
        .item-text-container {
          padding: 25px 15px;
          .item-headline {
            font-size: 20px;
          }
        }
        .icon {
          &.icon-heart {
            @include size(37px);
            top: -16px;
            margin-left: -20px;
          }
          &.icon-mouse {
            @include size(35px);
            top: -18px;
            margin-left: -19px;
          }
          &.icon-shield {
            @include size(35px);
            top: -16px;
            margin-left: -17px;
          }
          &.icon-hands {
            @include size(32px);
            top: -14px;
            margin-left: -16px;
          }
          &.icon-syringe {
            @include size(34px);
            top: -17px;
            margin-left: -17px;
          }
        }
      }
      &.bottom-offset-150 {
        margin-bottom: -40px;
      }
    }
    .image-boxes-text {
      width: 90%;
    }
    &.numbers {
      .items {
        margin-bottom: -130px;
        .item {
          margin-bottom: 60px;
          .image-wrapper {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .image-boxes {
    .image-boxes-headline {
      font-size: 22px;
    }
    .items {
      margin-top: 60px;
      margin-bottom: -130px;
      .item {
        margin-bottom: 60px;
        &.bigger {
          margin-top: 0;
        }
        .image-wrapper {
          height: 190px;
        }
        &.long {
          height: auto;
          .item-text-container {
            padding: 30px 20px 30px;
          }
        }
        .item-text-container {
          &.btn-fixed-bottom {
            padding-bottom: 70px !important;
            .btn {
              bottom: 25px;
            }
          }
        }
        .number {
          font-size: 26px;
          margin-left: -8px;
          top: -21px;
        }
      }
      &.bottom-offset-150 {
        margin-bottom: -120px;
      }
    }
    &.padding-top-0 {
      padding-top: 20px;
    }
    .image-boxes-text {
      width: 100%;
    }
  }
}