.blue-doctor {
  padding: 235px 0 65px 0;
  background-color: $vibrant-blue;
  text-align: center;
  color: #8cb4e3;
  position: relative;
  overflow: hidden;
  .blue-doctor-text {
    width: 60%;
    padding-left: 110px;
    position: relative;
  }
  .blue-doctor-headline {
    color: #fff;
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
  }
  &::before {
    @include pseudo;
    width: 708px;
    height: 430px;
    @include image('../images/backgrounds/blue-doctor.png');
    left: 50%;
    bottom: 0;
    margin-left: -70px;
  }
}

@media (max-width: 1599px) {
  .blue-doctor {
    &::before {
      margin-left: -115px;
    }
    .blue-doctor-text {
      width: 62%;
      padding-left: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .blue-doctor {
    &::before {
      margin-left: -210px;
    }
    padding-top: 210px;
    .blue-doctor-headline {
      width: 78%;
      font-size: 26px;
    }
  }
}

@media (max-width: 991px) {
  .blue-doctor {
    padding-top: 90px;
    .blue-doctor-text {
      padding-left: 0;
      width: 66%;
    }
    .blue-doctor-headline {
      width: 90%;
    }
    &::before {
      width: 730px;
      height: 440px;
      margin-left: -250px;
    }
  }
}

@media (max-width: 991px) {
  .blue-doctor {
    padding-bottom: 40px;
    .blue-doctor-text {
      width: 100%;
    }
    .blue-doctor-headline {
      width: 100%;
      font-size: 22px;
    }
    &::before {
      display: none;
    }
  }
}