.landing {
  height: 940px;
  background: no-repeat center top;
  background-image: url('../images/backgrounds/landing.jpg');
  padding: 0;
  .container {
    position: relative;
    height: 100%;
  }
  .landing-text {
    width: 680px;
    position: absolute;
    text-align: center;
    left: 140px;
    top: 50%;
    transform: translateY(-50%);
    h1 {
      line-height: 64px;
      margin-bottom: 45px;
    }
  }
}

.landing-subpage {
  background: no-repeat center top;
  background-image: url('../images/backgrounds/landing-subpage.jpg');
  padding: 220px 0 160px 0;
  min-height: 665px;

  &--blog {
    background-position: center bottom;
    padding: 160px 0 130px 0;
    min-height: 300px;

    & + section {
      padding: 0 0 90px;
    }
  }

  .landing-text {
    width: 50%;
    text-align: center;
    margin: auto;
    h1 {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 25px;
    }
    p {

    }
  }
  &.landing-subpage-pricelist {
    min-height: 840px;
    position: relative;
    &::before {
      width: 621px;;
      height: 453px;
      @include pseudo;
      left: 40%;
      bottom: 0;
      margin-left: -660px;
      @include image('../images/backgrounds/doctor.png');

      @media only screen and (max-width: 1365px) {
        display: none;
      }
    }
  }
  &.landing-subpage-thanks {
    min-height: 874px;
    position: relative;
    .landing-text {
      margin-right: 150px;
    }
    &::before {
      width: 870px;
      height: 500px;
      @include pseudo;
      left: 50%;
      bottom: 40px;
      margin-left: -750px;
      @include image('../images/backgrounds/thanks.png');
      pointer-events: none;
    }
  }
}

@media (max-width: 1599px) {
  .landing {
    background-size: 1650px;
    height: 810px;
    .landing-text {
      width: 560px;
      h1 {
        line-height: 45px;
      }
    }
  }
  .landing-subpage {
    min-height: 605px;
    background-size: 1760px;
    &--blog {
      min-height: 300px;
    }
    .landing-text {
      width: 52%;
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 26px;
      }
    }
    &.landing-subpage-pricelist {
      min-height: 730px;
      &::before {
        width: 530px;
        height: 386px;
      }
    }
    &.landing-subpage-thanks {
      min-height: 790px;
      &::before {
        width: 770px;
        height: 444px;
        bottom: 37px;
        margin-left: -610px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .landing {
    background-size: 1300px;
    height: 650px;
    .landing-text {
      width: 490px;
      h1 {
        line-height: 35px;
      }
    }
  }
  .landing-subpage {
    padding-top: 180px;
    background-size: 1620px;
    .landing-text {
      width: 60%;
    }
    &.landing-subpage-pricelist {
      min-height: 610px;
    }
    &.landing-subpage-thanks {
      min-height: 720px;
      &::before {
        width: 670px;
        height: 390px;
        margin-left: -505px;
      }
      .landing-text {
        margin-right: 40px;
      }
    }
  }
}

@media (max-width: 991px) {
  .landing {
    background-size: 1150px;
    height: 590px;
    .landing-text {
      left: 50px;
    }
  }
  .landing-subpage {
    background-size: 1430px;
    padding-top: 140px;
    min-height: 520px;
    padding-bottom: 120px;
    .landing-text {
      width: 90%;
      h1 {
        font-size: 28px;
      }
    }
    &.landing-subpage-thanks {
      min-height: 662px;
      .landing-text {
        width: 65%;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .landing {
    background-size: 840px;
    background-position: left top;
    height: 430px;
    .landing-text {
      padding-top: 100px;
      width: 100%;
      left: auto;
      top: auto;
      position: relative;
      transform: none;
      h1 {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  .landing-subpage {
    min-height: 330px;
    background-size: auto 100%;
    background-position: -200px top;
    padding-top: 100px;
    padding-bottom: 50px;
    &--blog {
      min-height: 200px;
    }
    .landing-text {
      width: 100%;
      h1 {
        font-size: 22px;
        line-height: 30px;
      }
      h2 {
        font-size: 20px;
      }
    }
    &.landing-subpage-pricelist {
      min-height: 400px;
      &::before {
        width: 310px;
        height: 216px;
        bottom: -30px;
        margin-left: -310px;
      }
    }
    &.landing-subpage-thanks {
      min-height: unset;
      padding-bottom: 100px;
      .landing-text {
        width: 100%;
      }
      &::before {
        display: none;
      }
    }
  }
}