.gallery {
  padding: 50px 0 100px 0;
  background-color: $light-blue-2;
  position: relative;
  .gallery-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .gallery-wrapper {
    width: 870px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 175px;
    .gallery-images {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .gallery-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include transition;
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .gallery-overlay {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1528px;
    height: 636px;
    @include image('../images/backgrounds/gallery-overlay.png');
    margin-left: -540px;
  }
  .gallery-headline {
    font-size: 30px;
    margin-bottom: 45px;
  }
  p {
    margin-bottom: 30px;
  }
  .gallery-thumbs {
    position: absolute;
    bottom: -30px;
    right: 120px;
    font-size: 0;
    z-index: 10;
    .gallery-thumb {
      width: 160px;
      height: 123px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 5px solid #fff;
      @include inline-block;
      cursor: pointer;
      position: relative;
      &::before {
        @include pseudo;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;
        left: 50%;
        margin-left: -10px;
        top: -15px;
        @include transition;
        opacity: 0;
      }
      &+.gallery-thumb {
        margin-left: 15px;
      }
      &.active {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &.gallery-subpage {
    text-align: center;
    padding-top: 95px;
    min-height: 765px;
    .gallery-overlay {
      width: 1206px;
      height: 761px;
      @include image('../images/backgrounds/gallery-overlay-2.png');
      bottom: -88px;
      margin-left: -407px;
    }
    &::before {
      width: 100%;
      height: 400px;
      left: 0;
      top: 0;
      @include pseudo;
      z-index: 1;
      background: -moz-linear-gradient(top, rgba(232,244,253,1) 0%, rgba(232,244,253,1) 37%, rgba(232,244,253,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(232,244,253,1) 0%,rgba(232,244,253,1) 37%,rgba(232,244,253,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(232,244,253,1) 0%,rgba(232,244,253,1) 37%,rgba(232,244,253,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8f4fd', endColorstr='#00e8f4fd',GradientType=0 );
    }
    .gallery-headline, p {
      z-index: 2;
      position: relative;
    }
    .gallery-headline {
      margin-bottom: 30px;
    }
    .icon-item {
      text-align: left;
      position: relative;
      z-index: 2;
      padding-top: 80px;
    }
  }
}

@media (max-width: 1599px) {
  .gallery {
    .gallery-overlay {
      margin-left: -660px;
    }
    .gallery-wrapper {
      margin-left: 60px;
    }
    &.gallery-subpage {
      .gallery-overlay {
        margin-left: -524px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .gallery {
    padding-top: 110px;
    .gallery-wrapper {
      margin-left: -8px;
    }
    .gallery-overlay {
      margin-left: -725px;
    }
    .gallery-thumbs {
      right: 20px;
    }
    .gallery-headline {
      font-size: 26px;
      margin-bottom: 30px;
    }
    &.gallery-subpage {
      .gallery-wrapper {
        margin-left: 14px;
      }
      .gallery-overlay {
        margin-left: -570px;
      }
    }
  }
}

@media (max-width: 991px) {
  .gallery {
    padding-bottom: 60px;
    .gallery-thumbs {
      display: none;
    }
    .gallery-wrapper {
      margin-left: 155px;
    }
    .gallery-overlay {
      margin-left: -565px;
    }
    &.gallery-subpage {
      padding-top: 60px;
      min-height: 690px;
      .icon-item {
        padding-top: 40px;
      }
      .gallery-wrapper {
        margin-left: 144px;
      }
      .gallery-overlay {
        margin-left: -440px;
        bottom: -90px;
      }
    }
  }
}

@media (max-width: 767px) {
  .gallery {
    padding-bottom: 40px;
    .gallery-headline {
      font-size: 22px;
    }
    .gallery-wrapper {
      display: none;
    }
    .gallery-overlay {
      margin-left: -715px;
      opacity: .6;
    }
    &.gallery-subpage {
      padding-top: 40px;
      padding-bottom: 10px;
      min-height: unset;
      .gallery-overlay {
        margin-left: -400px;
      }
    }
  }
}