.blue-offer {
  padding: 130px 0 100px 0;
  background-color: $blue;
  text-align: center;
  position: relative;
  overflow: hidden;
  &::before {
    @include pseudo;
    width: 708px;
    height: 482px;
    @include image('../images/backgrounds/blue-offer.png');
    bottom: 0;
    left: 50%;
    margin-left: -20px;
  }
  .blue-offer-headline {
    color: #fff;
    font-size: 30px;
    margin-bottom: 25px;
    position: relative;
  }
  .blue-offer-text {
    width: 55%;
    margin: auto;
    color: #fff;
    margin-bottom: 35px;
    position: relative;

    a {
      color: $light-blue-2;
    }
  }
  .blue-offer-list {
    text-align: left;
    li {
      color: #fff;
    }
    li::before {
      background-color: #fff;
    }
  }
  .circles {
    width: 640px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    .circle {
      @include size(120px);
      border: 2px solid #c1e0f8;
      color: #fff;
      font-size: 24px;
      text-align: center;
      font-family: 'Rubik', sans-serif;
      border-radius: 100%;
      padding-top: 35px;
      &.blue {
        border-color: $vibrant-blue;
        color: $vibrant-blue;
      }
    }
    .plus {
      @include size(26px);
      position: relative;
      &::before, &::after {
        @include pseudo;
        background-color: #fff;
      }
      &::before {
        width: 100%;
        height: 2px;
        top: 12px;
        left: 0;
      }
      &::after {
        height: 100%;
        width: 2px;
        left: 12px;
        top: 0;
      }
    }
  }
  .btn {
    position: relative;
  }
}

@media (max-width: 1199px) {
  .blue-offer {
    .blue-offer-headline {
      font-size: 26px;
    }
    .blue-offer-text {
      width: 80%;
    }
  }
}

@media (max-width: 991px) {
  .blue-offer {
    padding: 90px 0 60px 0;
    &::before {
      margin-left: -120px;
    }
  }
}

@media (max-width: 767px) {
  .blue-offer {
    padding: 80px 0 40px 0;
    &::before {
      display: none;
    }
    .blue-offer-headline {
      font-size: 22px;
    }
    .blue-offer-text {
      width: 100%;
    }
    .circles {
      width: 100%;
      .circle {
        @include size(70px);
        font-size: 16px;
        padding-top: 20px;

      }
      .plus {
        @include size(18px);
        &::before {
          top: 8px;
        }
        &::after {
          left: 8px;
        }
      }
    }
  }
}